import useIsScrolledDown from "@/hooks/useIsScrolledDown";
import { css } from "@emotion/react";
import { useEffect, useRef } from "react";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { CSSTransition } from "react-transition-group";
import SEO from "../SEO/SEO";
import GTag from "./GTag";
import FacebookPixel from "./FacebookPixel";
import openGraphImage from "../../../public/images/default-open-graph.jpg";
import Bing from "./Bing";
import { useCookies } from "react-cookie";

interface Props {
  title?: string;
  metaDescription?: string;
  hero?: React.ReactNode;
  children: React.ReactNode;
  heroContainerCss?: any;
  isArticle?: boolean;
  imageUrl?: string;
  skipScripts?: boolean;
}

const Layout = ({
  title,
  metaDescription,
  children,
  hero,
  heroContainerCss,
  isArticle = false,
  imageUrl = openGraphImage.src,
  skipScripts = false,
}: Props) => {
  const isScrolledDown = useIsScrolledDown(100);
  const nodeRef = useRef(null);
  const [cookies, setCookie] = useCookies(["jwwa_track"]);
  const jwwaTrack = cookies["jwwa_track"];

  useEffect(() => {
    if (jwwaTrack) return;

    const initialReferrer = window.document.referrer;
    const initialLandingPage = window.location.href;
    const initialDatetime = Date.now();
    setCookie(
      "jwwa_track",
      {
        initialReferrer,
        initialLandingPage,
        initialDatetime,
      },
      {
        domain:
          process.env.NODE_ENV === "development"
            ? "localhost"
            : ".jerseywatch.com",
        expires: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
      }
    );
  }, [jwwaTrack, setCookie]);

  return (
    <>
      {title && metaDescription && (
        <SEO
          title={title}
          description={metaDescription}
          isArticle={isArticle}
          imageUrl={imageUrl}
        />
      )}
      {!skipScripts && (
        <>
          <GTag />
          <Bing />
          <FacebookPixel />
        </>
      )}
      <div
        css={css`
          min-height: 100vh;
          position: relative;
        `}
      >
        <main>
          <CSSTransition
            nodeRef={nodeRef}
            in={isScrolledDown}
            timeout={200}
            classNames="header-scrolled-down"
            unmountOnExit
          >
            <div
              ref={nodeRef}
              css={css`
                position: fixed;
                width: 100%;
                z-index: 100;
                background: rgba(255, 255, 255, 0.8);
                border: 1px solid rgba(255, 255, 255, 0.1);
                backdrop-filter: blur(30px);
              `}
            >
              <Header />
            </div>
          </CSSTransition>
          <style global jsx>
            {`
              .header-scrolled-down-enter {
                opacity: 0;
              }
              .header-scrolled-down-enter-active {
                opacity: 1;
                transition: opacity 200ms;
              }
              .header-scrolled-down-exit {
                opacity: 1;
              }
              .header-scrolled-down-exit-active {
                opacity: 0;
                transition: opacity 200ms;
              }
            `}
          </style>
          <div css={heroContainerCss}>
            <Header />
            {hero || null}
          </div>
          {children}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Layout;
